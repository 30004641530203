import {Meta, PaginationRequest} from '~/types/types';
import {httpApiV3, useVzFetch} from '~/composables/useVzFetch';
import {draftOrder, draftOrderData} from '~/types/order/order';
import {IRequestError} from '~/types/api';
import {IOrderFavor} from '~/types/components/personal/order-list';

export function createDraft(orderData: draftOrder) {
  return useVzFetch(
      `${httpApiV3}/personal/order/draft?version=v1`,
      { method: 'post', body: { orderData } }
  ) as Promise<draftOrderData & { error?: IRequestError }>
}

export function draftList(requestData: PaginationRequest, signal: AbortSignal|undefined = undefined) {
  return useVzFetch(
      `${httpApiV3}/personal/order/draft`,
      { method: 'get', params: requestData, signal }
  ) as Promise<{ data: draftOrderData[], meta: Meta }>
}

export function updateDraft(id: string|number, orderData: draftOrder) {
  return useVzFetch(
      `${httpApiV3}/personal/order/draft/${id}`,
      { method: 'put', body: { orderData } }
  ) as Promise<draftOrderData & { error?: IRequestError }>
}

export function getDraft(id: string|number) {
  return useVzFetch(`${httpApiV3}/personal/order/draft/${id}`) as Promise<draftOrder & { error?: IRequestError }>
}

export function removeDraft(id: string|number) {
  return useVzFetch(
      `${httpApiV3}/personal/order/draft/${id}`,
      { method: 'delete' }
  ) as Promise<{ message: string } & { error?: IRequestError }>
}

export function getDraftFavorList() {
  return useVzFetch(`${httpApiV3}/personal/order/draft/favor`) as Promise<IOrderFavor[]>
}
